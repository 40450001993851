
import React, { useState } from 'react'
import toast from 'react-hot-toast'

function NewsletterModal() {
    const [isLoading, setIsLoading] = useState(false)
    const [email, setEmail] = useState("")
    const [message, setMessage] = useState({ details: "", type: "" })


    const subscribeNewsletter = async (e) => {
        e.preventDefault()

        setMessage({ details: "", type: "" })
        let data = {
            "email": email
        }
        setIsLoading(true)
        try {

            let url = `${process.env.REACT_APP_API_BASE_URL}/api/v1/subscriber`;

            const response = await fetch(url, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                redirect: "follow",
                referrerPolicy: "no-referrer",
                body: JSON.stringify(data),
            });
            let res = await response.json()

            if (!response.ok) {
                let emailErr = res["email"]
                setMessage({ details: emailErr.join("\n"), type: "error" })
            } else {
                setMessage({ details: res["detail"], type: "success" })
                setEmail("");
            }


        } catch (error) {
            toast.error(error.message)
        } finally {
            setIsLoading(false)
        }
    }

    const onModalClose = (e) => {
        e.preventDefault(); 
        setMessage({ details: "", type: "" })
        setEmail(""); 
        window.newsletter_modal.close()
        localStorage.setItem('newsletterLastClosedTimestamp', new Date().getTime());
    }

    return (
        <dialog id="newsletter_modal" className="modal">
            <form method="dialog" className="modal-box">
                <button onClick={onModalClose} className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2">✕</button>
                <h3 className="font-bold text-lg">Subscribe!</h3>
                <p className="py-4">Subscribe to our newsletter to get the latest articles and updates right in your inbox!</p>
                <input type="email" name="email" onInput={(e) => setEmail(e.target.value)} value={email} placeholder="Your Email" className="input input-bordered w-full " />
                {
                    isLoading ?
                        <button className="btn btn-primary btn-block">
                            <span className="loading loading-spinner"></span>
                        </button>
                        : <button onClick={subscribeNewsletter} className="btn btn-primary btn-block mt-4">Subscribe</button>
                }

                {
                    message.details && (<div className={`alert alert-${message.type} mt-2`}>
                        <svg xmlns="http://www.w3.org/2000/svg" className="stroke-current shrink-0 h-6 w-6" fill="none" viewBox="0 0 24 24"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z" /></svg>
                        <span>{message.details}</span>
                    </div>)
                }
            </form>

        </dialog>
    )
}

export default NewsletterModal