// Import the functions you need from the SDKs you need
import {initializeApp} from "firebase/app";
import {getMessaging, getToken} from "firebase/messaging";
import { getBrowserName, registerDeviceForNotification } from '@zoding/shared'



// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
    measurementId: process.env.REACT_APP_GA_ID
};


// Initialize Firebase
const app = initializeApp(firebaseConfig);
const messaging = getMessaging(app);


export const getFCMToken = () => {
    return getToken(messaging, {vapidKey: process.env.REACT_APP_FIREBASE_VAPIDKEY}).then((currentToken) => {
        if (currentToken) {
            console.log('current token for client: ', currentToken);
            // Track the token -> client mapping, by sending to backend server
            registerDeviceForNotification({
                "type": getBrowserName(),
                "token": currentToken
            }).then(r => console.log("Registered Device")).catch((e) => console.log(e))

        } else {
            console.log('No registration token available. Request permission to generate one.');

        }
    }).catch((err) => {
        console.log('An error occurred while retrieving token. ', err)
    });
}