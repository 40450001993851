import { createContext, useState } from 'react'

export const AppContext = createContext('')


export function AppProvider({ children }) {
  const [state, setState] = useState({
    filters: {
      source: [],
      search: ''
    },
    post: {
      selected: null
    },
    papers: {
      selected: null,
      search: null
    }
  })

  const updateState = (newState) => {
    setState({ ...state, ...newState })
  }

  return (
    <AppContext.Provider value={{ state, updateState }}>
      {children}
    </AppContext.Provider>
  )
}
  