import { useContext } from 'react'
import { SocialIcon } from 'react-social-icons'
import toast from 'react-hot-toast'
import { AppContext } from '../state'
import { getShareLink, ShareProvider } from '../utils'

export default function SharePostModal(props) {

  const { state, _ } = useContext(AppContext)

  const post = state.post.selected

  const handleSocialIconClick = (provider) => {
    let siteUrl = 'https://zoding.app'

    let text = `Check out this amazing article! \nn ${post.title} \n👉 Click here to read ${post.url} \n👉 Find more such articles on ${siteUrl}`

    let openUrl = getShareLink({ provider, link: post.url, text })

    if (provider === ShareProvider.CopyLink) {
      toast.success("Copied to your clipboard!")
    }else{
      window.open(openUrl, '_blank')
    }

  }

  return (
    <>
      <dialog id="share_modal" className="modal">
        <form method="dialog" className="modal-box">
          <button className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2">✕</button>
          <h3 className="font-bold text-lg">Share</h3>
          <p className="py-4">Share this article with your friends!</p>
          <div className="grid grid-cols-6 gap-4">
            <button onClick={(e) => handleSocialIconClick(ShareProvider.CopyLink)} aria-pressed="false"
                    className="btn btn-circle flex-row items-center border typo-callout font-bold no-underline shadow-none cursor-pointer select-none focus-outline justify-center flex relative">
              <svg width="1em" height="1em" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"
                   className="w-8 h-8 pointer-events-none text-theme-label-invert">
                <path
                  d="M6.091 8.625a.844.844 0 01.115 1.68l-.115.008c-.727 0-1.324.552-1.396 1.26l-.008.143v6.19c0 .728.554 1.327 1.263 1.4l.144.006h6.185c.728 0 1.327-.553 1.399-1.262l.007-.144a.844.844 0 111.687 0 3.094 3.094 0 01-2.905 3.088L12.28 21H6.094a3.094 3.094 0 01-3.088-2.905L3 17.906v-6.19a3.091 3.091 0 013.091-3.091zM17.906 3a3.094 3.094 0 013.088 2.905l.005.189v6.187a3.094 3.094 0 01-2.905 3.088l-.188.006h-6.189A3.094 3.094 0 018.63 12.47l-.005-.189V6.094a3.094 3.094 0 012.905-3.088L11.717 3h6.189zm0 1.688h-6.189c-.728 0-1.327.553-1.399 1.262l-.007.144v6.187c0 .728.554 1.327 1.263 1.4l.143.007h6.189c.728 0 1.327-.554 1.399-1.263l.007-.144V6.094c0-.728-.554-1.327-1.263-1.4l-.143-.006z"
                  fill="currentcolor" fillRule="evenodd"></path>
              </svg>
            </button>
            {
              Object.entries(ShareProvider).map(([providerKey, providerValue]) => {

                if(providerValue === ShareProvider.CopyLink) return;

                return <span className="cursor-pointer" key={providerValue}><SocialIcon
                  onClick={() => handleSocialIconClick(providerValue)} network={providerValue} /></span>
              })

            }
          </div>
        </form>
      </dialog>
    </>
  )
}
