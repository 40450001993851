import { useState } from "react";

export default function Actionbar(props) {
    const { onTabChange } = props
    const [selectedTab, setSelectedTab] = useState("Latest");

    const handleTabClick = (tab) => {
        setSelectedTab(tab);
        onTabChange(tab)
    };

    return (
        <div className="col-span-4 flex justify-center mb-5 mt-5 text-lg font-bold">
            <button
                className={`mr-4 border-b-2 hover:border-primary transition-all ${selectedTab === "Latest" ? "border-primary" : "border-transparent"
                    }`}
                onClick={() => handleTabClick("Latest")}
            >
                Latest
            </button>
            {/* <span className="text-gray-400">|</span> */}
            <div className="divider lg:divider-horizontal"></div>
            <button
                className={`ml-4 border-b-2 hover:border-primary transition-all ${selectedTab === "Trending" ? "border-primary" : "border-transparent"
                    }`}
                onClick={() => handleTabClick("Trending")}
            >
                Trending
            </button>
        </div>
    );
}
