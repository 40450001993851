

export async function getArticles(queryParams) {
    try {
        // Build the URL with the provided query parameters
        let url = `${process.env.REACT_APP_API_BASE_URL}/api/v1/articles${queryParams}`;

        const response = await fetch(url);

        if (!response.ok) {
            let res = await response.json();
            throw new Error(res.detail || 'Failed to fetch data from the API');
        }

        return await response.json()
    } catch (error) {
        throw new Error(error.message);
    }
}


export async function getResearchPapers(queryParams) {
    try {
        // Build the URL with the provided query parameters
        let url = `${process.env.REACT_APP_API_BASE_URL}/api/v1/research-papers${queryParams}`;

        const response = await fetch(url);

        if (!response.ok) {
            let res = await response.json();
            throw new Error(res.detail || 'Failed to fetch data from the API');
        }

        return await response.json()
    } catch (error) {
        throw new Error(error.message);
    }
}

export async function sendMessage(formData) {
    try {
        let url = `${process.env.REACT_APP_API_BASE_URL}/api/v1/contact`;

        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(formData),
        });

        if (!response.ok) {
            let res = await response.json();
            throw new Error(res.detail || 'Failed to submit your message!Please try again.');
        }

        return await response.json();
    } catch (error) {
        throw new Error(error.message);
    }
}


export async function getSuggestions(excluded = null) {
    try {
        // Build the URL with the provided query parameters
        let url = `${process.env.REACT_APP_API_BASE_URL}/api/v1/articles/suggest-me`;

        if (excluded) {
            url += "?exclude=" + excluded.join(",")
        }

        const response = await fetch(url);

        if (!response.ok) {
            let res = await response.json();
            throw new Error(res.detail || 'Failed to fetch data from the API');
        }

        return await response.json()
    } catch (error) {
        throw new Error(error.message);
    }
}

export async function registerDeviceForNotification(deviceData) {
    try {
        let url = `${process.env.REACT_APP_API_BASE_URL}/api/v1/notifications/register-device`;

        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(deviceData),
        });

        if (!response.ok) {
            let res = await response.json();
            console.log("Error:", res.detail)
        }

        return await response.json();
    } catch (error) {
        throw new Error(error.message);
    }
}