
const CACHE_PREFIX = "zod_";

export const localStorageCacheProvider = {
  async get(key) {
    const cachedItem = localStorage.getItem(CACHE_PREFIX + key);
    if (cachedItem) {
      const { data, expiry } = JSON.parse(cachedItem);
      if (expiry > Date.now()) {
        return data;
      } else {
        localStorage.removeItem(CACHE_PREFIX + key); // Remove expired item from cache
      }
    }
    return null;
  },
  async set(key, data, durationInMinutes) {
    const expiry = Date.now() + durationInMinutes * 60 * 1000;
    const item = { data, expiry };
    localStorage.setItem(CACHE_PREFIX + key, JSON.stringify(item));
  }
};
