import { useContext, useEffect, useState } from 'react'
import SuggestMeModal from './SuggestMeModal'
import { IconFilter, IconShuffle } from './Icons'
import { AppContext } from '../state'
import { TrackAnalyticsEvent } from '../utils'

export default function ResearchPapersFilterBar(props) {
  const [searchValue, setSearchValue] = useState('')
  const { state, updateState } = useContext(AppContext)

  const [helpText, setHelpText] = useState('')


  const handleInputChange = (event) => {
    setSearchValue(event.target.value)
    if (event.target.value.length === 0) {
      setHelpText('')
    } else {
      setHelpText('Hit \'Enter\' to search')
    }
  }

  const onKeyUpHandler = (event) => {
    if (event.keyCode === 13 || searchValue.length === 0) {
      updateState({ ...state, papers: { search: searchValue } })
      TrackAnalyticsEvent('searchInput', { value: event.target.value })
    }
  }

  return (
    <>

      <div className="flex-initial w-full">
        <div className="flex items-center justify-between mt-5">
          <div className="flex items-center">
            <span className="text-base italic">Curated Brilliance: Unveil the Top Research Papers, Handpicked for You</span>
          </div>
          <div className="flex items-center">
            <span className="text-xs mr-2 italic text-slate-400">
              {helpText}
            </span>
            <input
              type="text"
              className="input w-20 md:w-auto sm:w-auto input-sm input-bordered input-primary mr-4 sm-input-w"
              placeholder="Search"
              value={searchValue}
              onChange={handleInputChange}
              onKeyUp={onKeyUpHandler}
            />
          </div>
        </div>
      </div>
    </>
  )
}
