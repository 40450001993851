import {Link} from "react-router-dom"
import {askNotificationPermission} from "../utils/notification";

function Footer() {
    return (

        <footer className="footer p-10 bg-base-200 text-base-content">
            <aside>
                <img src="/logo.png" alt="Zoding.dev" width="40px" />
                <p>Zoding.app - Elevate Your Software Engineering IQ!</p>
                <p>Copyright © {new Date().getFullYear()} - All right reserved</p>
            </aside>

            <nav>
                <header className="footer-title">INFO</header>
                <Link to="/" className="link link-hover">Home</Link>
                <Link to="/about" className="link link-hover">About us</Link>
                <Link to="/contact" className="link link-hover">Contact</Link>

            </nav>
            <nav>
                <header className="footer-title">UPDATES</header>
                <button className="link link-hover" onClick={() => {
                    window.newsletter_modal.showModal()
                }}>Subscribe To Newsletter
                </button>
                <button className="link link-hover" onClick={() => {
                    askNotificationPermission()
                }}>Enable Notification
                </button>

            </nav>
            <nav>
                <header className="footer-title">LEGAL</header>

                <Link to="/privacy" className="link link-hover">Privacy Policy</Link>

            </nav>
        </footer>
    )
}


export default Footer