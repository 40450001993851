import { getBrowserName } from '@zoding/shared'

export const getExtensionIcon = () => {
  // Returns an icon with link to the extension download page
  const browserName = getBrowserName()

  const firefoxIcon = <a target="_blank" className="hide-element-on-mobile mr-4 tooltip tooltip-left" data-tip="Download Firefox Extension"
                         href="https://addons.mozilla.org/en-US/firefox/addon/zoding-app/?utm_source=webapp" rel="noopener noreferrer">
    <img className="w-8" src="/firefox-addons.png" alt="Firefox Extension" />
  </a>

  const chromeIcon = <a target="_blank" className="hide-element-on-mobile mr-4 tooltip tooltip-left" data-tip="Download Chrome Extension"
                        href="https://chromewebstore.google.com/detail/zodingapp/fncekljkknoechochggaleapamlioico?utm_source=webapp" rel="noopener noreferrer">
    <img className="w-8" src="./chrome-extension-icon.png" alt="Chrome Extension" />
  </a>

  if (browserName === 'Firefox') {
    return firefoxIcon;
  } else if (browserName === 'Chrome') {
    return chromeIcon;
  } else {
    // If the browser is not Firefox or Chrome, return null or any default component
    return null;
  }
}
