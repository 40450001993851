import {useContext, useEffect, useState} from "react";
import SuggestMeModal from "./SuggestMeModal";
import {IconFilter, IconShuffle} from "./Icons";
import { AppContext } from '../state'
import { TrackAnalyticsEvent } from '../utils'

export default function FilterBar(props) {
    const {onFilterClick} = props;
    const [searchValue, setSearchValue] = useState("");
    const {state, updateState} = useContext(AppContext);

    const [helpText, setHelpText] = useState("");

    const [isSuggestMeModalShown, setIsSuggestMeModalShown] = useState(false)

    useEffect(() => {
        document.getElementById('suggest_me_modal').addEventListener('close', (_) => {
            setIsSuggestMeModalShown(false)
        });
    }, []);


    const handleInputChange = (event) => {
        setSearchValue(event.target.value);

        if (event.target.value.length === 0) {
            setHelpText("");
        } else {
            setHelpText("Hit 'Enter' to search");
        }
    };

    const onKeyUpHandler = (event) => {
        if (event.keyCode === 13 || searchValue.length === 0) {
            updateState({...state, filters: {search: searchValue}});
            TrackAnalyticsEvent('searchInput', {value: event.target.value});
        }
    };

    const onSuggestMeClick = async () => {
        setIsSuggestMeModalShown(true)
        window.suggest_me_modal.showModal();
        TrackAnalyticsEvent('suggestMeModalOpened')

    };

    return (
        <>
            <SuggestMeModal isShown={isSuggestMeModalShown}/>
            <div className="flex-initial w-full">
                <div className="flex items-center justify-between">
                    <div className="flex items-center">
                        <button
                            className="btn btn-ghost btn-sm text-xs"
                            onClick={onFilterClick}
                        >
                            Filter
                            <IconFilter height="1.5em"/>
                        </button>
                        <button
                            className="btn btn-ghost btn-sm text-xs left-align"
                            onClick={onSuggestMeClick}
                        >
                            Suggest Me
                            <IconShuffle height="1em"/>
                        </button>
                    </div>
                    <div className="flex items-center">
            <span className="text-xs mr-2 italic text-slate-400">
              {helpText}
            </span>
                        <input
                            type="text"
                            className="input w-20 md:w-auto sm:w-auto input-sm input-bordered input-primary mr-4 sm-input-w"
                            placeholder="Search"
                            value={searchValue}
                            onChange={handleInputChange}
                            onKeyUp={onKeyUpHandler}
                        />
                    </div>
                </div>
            </div>
        </>
    );
}
