import React from "react";
// Import Swiper React components
import {Swiper, SwiperSlide} from "swiper/react";

import "swiper/css";

// import required modules
import {EffectCards} from "swiper/modules";

export default function CardsSwiper(props) {
    const {cards, onLastSlideReach} = props;

  

  return (
    <>
      <Swiper
        effect={"cards"}
        autoHeight={true}
        grabCursor={true}
        modules={[EffectCards]}
        className="mySwiper"
        onReachEnd={() => onLastSlideReach()}

      >
        {
            cards.map((card, index) => {
                return (<SwiperSlide key={index}>{card}</SwiperSlide>)
            })
        }
      </Swiper>
    </>
  );
}
