import CardsSwiper from "./Swiper";
import React, {useContext, useEffect, useState} from "react";
import toast from "react-hot-toast";
import {IconReload} from "./Icons";
import {getSuggestions} from '../api';
import Card from "./Card";
import { AppContext } from '../state'
import { TrackAnalyticsEvent } from '../utils'


export default function SuggestMeModal(props) {
    const {isShown} = props
    const [posts, setPosts] = useState([]);
    const [isPostsLoading, setIsPostsLoading] = useState(true);
    const [endOfSlides, setEndOfSlides] = useState(false);

    const {state, updateState} = useContext(AppContext)


    const loadPosts = async () => {
        setPosts([]);
        setEndOfSlides(false)
        setIsPostsLoading(true);

        let suggestedPosts = JSON.parse(sessionStorage.getItem("suggestedPosts")) || []
        try {
            const data = await getSuggestions(suggestedPosts);

            if (data.length === 0) {
                window.suggest_me_modal.close();
                toast((t) => (
                    <span>😔 No Posts Available For Suggestion!

                  </span>
                ));
            }


            setPosts(data);
            setIsPostsLoading(false);
            data.forEach((post) => suggestedPosts.push(post.id))

            sessionStorage.setItem("suggestedPosts", JSON.stringify(suggestedPosts))

        } catch (error) {
            console.error("Failed to load posts:", error);
            toast.error(error.message || 'Failed to load posts!')

        }
    };

    const onShareBtnClick = (post) => {
      updateState({...state, post: {selected: post}})
        window.share_modal.showModal();
        TrackAnalyticsEvent('SuggestMeModalShareButtonClicked', {"post": post.id, "title": post.title});
    };

    const onReadBtnClick = (post) => {
        TrackAnalyticsEvent('SuggestMeModalReadButtonClicked', {value: event.target.value});
        window.open(`${process.env.REACT_APP_API_BASE_URL}/api/v1/article/redirect/?url=${post.url}`, '_blank');
    }


    useEffect(() => {

        document.getElementById('suggest_me_modal').addEventListener('close', (_) => {
            setEndOfSlides(false)
            setPosts([]);
            setIsPostsLoading(false);
        });


        if (isShown) {
            loadPosts();
        }

        // Cleanup function to prevent memory leaks
        return () => {
            setEndOfSlides(false)
            setPosts([]);
            setIsPostsLoading(false);
        };
    }, [isShown]);

    function onReloadBtnClick(e) {
        e.preventDefault();
        loadPosts();
    }

    return (
        <dialog id="suggest_me_modal" className="modal">
            <form method="dialog" className="lg:w-1/2 modal-box w-auto min-h-[30rem]">
                <button className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2">✕</button>
                <h3 className="font-bold text-lg mb-3">Suggestions For You!</h3>
                <div className="flex flex-col items-center justify-center">
                    <div className="form-control mb-2">
                        <label className="text-md text-gray-500">
                            {isPostsLoading && !endOfSlides ? "Picking some articles for you..." : "We've picked some articles for you to read!"}
                        </label>
                        <label className="text-md text-gray-500">
                            {endOfSlides && (
                                <div className="flex items-center justify-center">
                                    <span className="text-sm">Want to see more? </span>
                                    <button className="btn btn-xs btn-ghost" onClick={(e) => {
                                        onReloadBtnClick(e);
                                    }}>
                                        <IconReload height="1.5em"
                                                    width="1.5em"/>
                                    </button>
                                </div>
                            )}
                        </label>
                    </div>
                </div>
                <div className="flex justify-center items-center h-full mt-4 no-scrollbar w-auto overflow-x-hidden">
                    {isPostsLoading ? (
                        <div className="flex items-center justify-center h-full">
                            <span className="loading loading-infinity loading-lg text-primary"></span>
                        </div>
                    ) : (
                        <CardsSwiper
                            cards={posts.map((post) => (
                                <Card key={post.id} post={post}
                                      onShareClick={onShareBtnClick}
                                      onReadClick={onReadBtnClick}
                                />
                            ))}
                            onLastSlideReach={() => setEndOfSlides(true)}
                        />
                    )}
                </div>
                <br/>
                {!isPostsLoading && (
                    <div className="flex items-center justify-center mt-4">
                        <label className="text-xs text-gray-400">
                            Use mouse to swipe left & right.
                        </label>
                    </div>
                )}
            </form>
        </dialog>
    );
}
