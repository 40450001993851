import { useContext, useEffect, useState } from 'react'
import { AppContext } from '../state'
import { getArticles, getResearchPapers } from '../api'
import toast from 'react-hot-toast'
import { localStorageCacheProvider } from '../cache/localCacheProvider'

export function useResearchPapers(useCache = false, cacheDurationInMinutes = 60) {
  const [papers, setPapers] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const [page, setPage] = useState(1)
  const [hasMore, setHasMore] = useState(true)
  const [isLoadingMore, setIsLoadingMore] = useState(false)
  const [loadingMsg, setLoadingMsg] = useState('Load More')
  const { state } = useContext(AppContext)


  const loadPapers = async () => {
    setIsLoading(true)

    if (!isLoadingMore) {
      setIsLoading(true)
    }

    let queryParams = `?page=${page}`

    if (state.papers.search && state.papers.search.length >= 4) {
      // Only search, remove source filter
      queryParams += `&search=${state.papers.search}`
    }

    try {
      let data
      if (useCache) {
        data = await localStorageCacheProvider.get(queryParams) // Try to get data from cache
      }
      if (!data) {
        data = await getResearchPapers(queryParams)
        if (useCache) {
          await localStorageCacheProvider.set(queryParams, data, cacheDurationInMinutes)
        }
      }

      if (data.results.length === 0 && isLoadingMore) {
        setLoadingMsg('That\'s all for now!')
        setHasMore(false) // No more data available
        return
      }
      if (isLoadingMore) {
        setPapers([...papers, ...data.results])
      } else {
        setPapers(data.results)
      }
      if (data.num_pages === page) {
        // No more pages to load
        setHasMore(false)
        setLoadingMsg('That\'s all for now!')
      }
    } catch (error) {
      console.error('Error fetching papers:', error)
      toast.error(error.message || 'Error fetching papers!')
    } finally {
      // Re-enable loading state
      setIsLoadingMore(false)
      setIsLoading(false)
    }
  }

  useEffect(() => {
    loadPapers()
  }, [state.papers, page, useCache])

  const loadMorePapers = () => {
    if (hasMore) {
      setIsLoadingMore(true)
      // Increment the page number
      setPage(page + 1)
    }
  }

  return {
    papers,
    isLoading,
    isLoadingMore,
    loadMorePapers,
    loadingMsg,
    setPage
  }
}
