import { useContext } from 'react'
import { AppContext } from '../state'
import Card from './Card'
import { TrackAnalyticsEvent } from '../utils/analytics'

function CardWrapper(props) {

    const {post} = props;

    const {state, updateState} = useContext(AppContext)

    const onReadBtnClick = (post) => {
        window.open(`${process.env.REACT_APP_API_BASE_URL}/redirect/?url=${post.url}&type=post`, '_blank');
        TrackAnalyticsEvent('ReadButtonClicked')

    }

    const onShareBtnClicked = (post) => {
        updateState({...state, post: {selected: post}})
        window.share_modal.showModal()
        TrackAnalyticsEvent('ShareButtonClicked')
    }

    return <Card
        post={post}
        onShareClick={onShareBtnClicked}
        onReadClick={onReadBtnClick}
    />
}

export default CardWrapper;


