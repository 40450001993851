import React, {useState} from 'react';
import toast from 'react-hot-toast';
import { sendMessage } from '../api'

export default function Contact() {
    const [formData, setFormData] = useState({
        email: '',
        name: '',
        subject: '',
        message: '',
    });

    const [errors, setErrors] = useState({});


    const handleChange = (e) => {
        const { id, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [id]: value,
        }));
    };

    const validateForm = () => {
        const newErrors = {};

        if (!formData.email) {
            newErrors.email = 'Email is required';
        }

        if (!formData.name) {
            newErrors.name = 'Name is required';
        } else if (formData.name.length < 3) {
            newErrors.name = 'Name must be at least 3 characters';
        }

        if (!formData.subject) {
            newErrors.subject = 'Subject is required';
        } else if (formData.subject.length < 5) {
            newErrors.subject = 'Subject must be at least 5 characters';
        }

        if (!formData.message) {
            newErrors.message = 'Message is required';
        } else if (formData.message.length < 10) {
            newErrors.message = 'Message must be at least 10 characters';
        }

        setErrors(newErrors);

        // Check if there are any errors
        return Object.keys(newErrors).length === 0;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (validateForm()) {
            try {
                const response = sendMessage(formData);

                if (response) {
                    
                    toast.success('Message sent successfully!');
                    // Optionally, you can reset the form after a successful submission
                    setFormData({
                        email: '',
                        name: '',
                        subject: '',
                        message: '',
                    });
                }
            } catch (error) {
                toast.error('Error sending form data: ' + error);
            }
        } else {
            // Form is not valid, handle the errors as needed
            toast.error('Form has errors')
        }
    };

    return (
        <section>
            <div className="py-8 lg:py-16 px-4 mx-auto max-w-screen-md">
                <h2 className="mb-4 text-4xl tracking-tight font-extrabold text-center text-gray-900 dark:text-white">Contact Us</h2>
                <p className="mb-8 lg:mb-16 font-light text-center text-gray-500 dark:text-gray-400 sm:text-xl">Got a technical issue? Want to send feedback? Do you have any suggestion or know any some good article source? Let us know.</p>
                <form action="#" className="space-y-8" onSubmit={handleSubmit}>
                    <div>
                        <label htmlFor="email" className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">Your email</label>
                        <input value={formData.email} type="email" id="email" className="input input-bordered input-warning  w-full bg-gray-50 dark:bg-gray-700" placeholder="john@doe.com" onChange={handleChange} />
                        {errors.email && <p className="text-red-500">{errors.email}</p>}
                    </div>
                    <div>
                        <label htmlFor="name" className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">Your name</label>
                        <input value={formData.name} type="text" id="name" className="input input-bordered input-warning  w-full bg-gray-50 dark:bg-gray-700" placeholder="John Doe" onChange={handleChange} />
                        {errors.name && <p className="text-red-500">{errors.name}</p>}
                    </div>
                    <div>
                        <label htmlFor="subject" className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">Subject</label>
                        <input value={formData.subject} type="text" id="subject" className="input input-bordered input-warning  w-full bg-gray-50 dark:bg-gray-700" placeholder="Subject Line" onChange={handleChange} />
                        {errors.subject && <p className="text-red-500">{errors.subject}</p>}
                    </div>
                    <div className="sm:col-span-2">
                        <label htmlFor="message" className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-400">Your message</label>
                        <textarea id="message" rows="6" className="textarea textarea-primary w-full bg-gray-50 dark:bg-gray-700" placeholder="Type your message here..." onChange={handleChange} value={formData.message}></textarea>
                        {errors.message && <p className="text-red-500">{errors.message}</p>}
                    </div>
                    
                    <button type="submit" className="btn btn-primary py-3 px-5 text-sm font-medium rounded-lg">Send message</button>
                </form>
            </div>
        </section>
    );
}
