import { AppContext } from '../state'
import toast from 'react-hot-toast'
import { useContext, useEffect, useState } from 'react'
import { localStorageCacheProvider } from '../cache/localCacheProvider'

export function useSources(useCache = true, cacheDurationInMinutes = 60) {
  const [sources, setSources] = useState([])
  const { state } = useContext(AppContext)

  const fetchSources = async () => {
    try {
      let data;
      if (useCache) {
        data = await localStorageCacheProvider.get('sources');
      }
      if (!data) {
        const url = `${process.env.REACT_APP_API_BASE_URL}/api/v1/source${state.filters.search ? `?search=${state.filters.search}` : ''}`
        const response = await fetch(url)

        if (!response.ok) {
          let res = await response.json()
          toast.error(res.detail || 'Failed to fetch filter data from the API')
        }

        data = await response.json();
        if (useCache) {
          await localStorageCacheProvider.set('sources', data, cacheDurationInMinutes); // Cache the data for 60 minutes
        }
      }

      setSources(data);
    } catch (error) {
      toast.error(error.message)
      console.error(error)
    }
  }

  useEffect(() => {
    fetchSources()
  }, [state.filters.search, useCache])

  return sources
}
