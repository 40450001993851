import React from 'react';
import { Link } from 'react-router-dom';

function About() {
  return (
    <section>

      <div className="py-8 lg:py-16 px-4 mx-auto max-w-screen-md">
        <h2 className="mb-4 text-4xl tracking-tight font-extrabold text-center text-gray-900 dark:text-white">Why I created this?</h2>
        <p className="mb-4 lg:mb-8 font-light text-left text-gray-500 dark:text-gray-400 sm:text-xl">
        I created this project because the internet is flooded with articles written by less experienced developers on how to do X in Y language & like those "Top Ten VS Code Plugins" and such. 
        It can be frustrating to wade through a plethora of content created by developers who are still learning the ropes. I built this app to address this issue and offer a solution.

        </p>
        <p className="mb-4 lg:mb-8 font-light text-left text-gray-500 dark:text-gray-400 sm:text-xl">
          I'm all about hooking you up with awesome engineering info. I personally curate the juiciest articles from top-notch sources and amazing authors, so you don't have to sift through the blah stuff. Whether you're a seasoned engineer or just dreaming of becoming one, I've got all kinds of topics and angles to pique your curiosity. From the coolest tech trends to brainy solutions, my platform is a treasure trove of engineering goodies.
          It's designed to keep your brain gears spinning and provide you with a dose of engineering awesomeness through my handpicked articles.
        </p>

        <p className="mb-4 lg:mb-8 font-light text-left text-gray-500 dark:text-gray-400 sm:text-xl">
          With my app, you'll find a diverse range of topics and perspectives carefully selected to cater to your interests. It's not just about providing information; it's about keeping you informed and inspired as you explore the fascinating world of engineering. So, don't waste your time sifting through the noise. 
          Let me do the heavy lifting, and you can enjoy the best engineering articles right at your fingertips. It's engineering made easy, engaging, and fun!
        </p>

        <p className="mb-4 lg:mb-8 font-light text-left text-gray-500 dark:text-gray-400 sm:text-xl">If you have any questions or suggestions for me, or this project, do not hesitate to contact us using our <Link to="/contact" className='link'>Contact Page</Link></p> 

      </div>
    </section>

  );
}

export default About;
