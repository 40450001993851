import { useContext, useState } from 'react'
import { capitalize } from '../utils'
import { AppContext } from '../state'
import { useSources } from '../hooks'


export default function FilterModel(props) {
  const [searchTerm, setSearchTerm] = useState('')
  const [selectedFilters, setSelectedFilters] = useState([])
  const sources = useSources()
  const { state, updateState } = useContext(AppContext)

  const handleCheckboxChange = (event) => {
    const { value } = event.target

    setSelectedFilters((prevSelectedFilters) => {
      if (prevSelectedFilters.includes(value)) {
        return prevSelectedFilters.filter((filter) => filter !== value)
      } else {
        return [...prevSelectedFilters, value]
      }
    })
  }

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value)
  }

  const filteredFilters = sources.filter((filter) =>
    filter.name.toLowerCase().includes(searchTerm.toLowerCase())
  )



  const applyFilters = (e) => {
    e.preventDefault()
    updateState({ ...state, filters: { source: selectedFilters, search: state.filters.search } })
    window.filter_modal.close()
  }

  const clearFilters = (e) => {
    window.filter_modal.close()
    setSelectedFilters([])
    updateState({ ...state, filters: { source: [], search: state.filters.search } })
  }

  return (
    <>
      <dialog id="filter_modal" className="modal">
        <form method="dialog" className="lg:w-1/2 modal-box w-auto min-h-[30rem]">
          <button className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2">✕</button>
          <h3 className="font-bold text-lg mb-3">Filter By Source</h3>
          <div className="flex flex-col justify-between">
            <div className="form-control">
              <input
                type="text"
                className="input w-full max-w input-sm input-primary mb-5"
                placeholder="Search"
                value={searchTerm}
                onChange={handleSearchChange}
              />
            </div>
            <div className="form-control">
              <div className="flex items-center justify-between mb-2">
                <label className="text-md text-gray-500">Filter By</label>
                {selectedFilters.length > 0 && (
                  <button
                    className="btn btn-ghost btn-xs text-xs"
                    onClick={clearFilters}
                  >
                    clear all
                  </button>
                )}
              </div>
              <ul className="max-h-[18rem] overflow-y-auto px-2 mb-4">
                {filteredFilters.map((source) => (
                  <li key={source.name} className="m-1 flex items-center justify-between">
                    <div className="flex items-center">
                      <div className="rounded-full w-8 h-8 overflow-hidden border">
                        <img
                          className="object-cover w-full h-full"
                          src={source.logo_url}
                          alt="Logo"
                        />
                      </div>
                      <div className="form-control ml-3">
                        <label className="cursor-pointer label font-medium">
                          <span className="label-text text-base">{capitalize(source.name)}</span>
                        </label>
                      </div>
                    </div>
                    <div className="form-control">
                      <label className="cursor-pointer label font-medium">
                        <input
                          type="checkbox"
                          className="checkbox checkbox-xs checkbox-warning"
                          value={source.name}
                          checked={selectedFilters.includes(source.name)}
                          onChange={handleCheckboxChange}
                        />
                      </label>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
            <div className="form-control">
              <button className="btn btn-block btn-primary" onClick={applyFilters}>Apply</button>
            </div>
          </div>
        </form>
      </dialog>
    </>
  )
}
