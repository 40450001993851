import { capitalize, formatDate, intToAbbr } from '../utils'

export const RPCard = (props) => {
  /**
   * Card to display research paper content
   */

  const { paper, onReadClick, onViewAbstractClick } = props

  return (
    <>
      <div className="card h-auto bg-base-100 dark:bg-base-200 shadow-xl lg:card-compact  sm-margin-5">

        <div className="card-body">
          <div className="card-title self-start">

            <div className="flex">
              <p onClick={(e) => onReadClick(paper)}
                  className="text-base text-ellipsis cursor-pointer transition duration-300 hover:underline">{paper.title}</p>


            </div>
          </div>
          <div className="flex-auto ml-3">
            <div className="flex flex-wrap gap-2">
              {
                paper.categories.map(category => (
                  <span key={category.id}
                    className="text-nowrap bg-gray-100 text-gray-800 text-xs font-medium px-2 py-0.5 rounded dark:bg-gray-700 dark:text-gray-300">#{category.name}</span>
                ))
              }


            </div>
            <br />
            <div className="text-xs font-normal text-left">
              <span>{formatDate(paper.published_at)}</span>&nbsp;&middot;&nbsp;

              <span>{intToAbbr(paper.views)} views</span>
            </div>

          </div>

          <div className="mt-3 flex justify-between">
            {/* https://devdojo.com/tailwindcss/buttons */}
            <button onClick={(e) => {
              onViewAbstractClick(paper)
            }}
                    className="btn-xs relative inline-flex items-center justify-start px-6 py-3 overflow-hidden font-medium transition-all bg-white dark:bg-dark-grey rounded hover:bg-white group">
                        <span
                          className="w-48 h-48 rounded rotate-[-40deg] btn-primary absolute bottom-0 left-0 -translate-x-full ease-out duration-500 transition-all translate-y-full mb-9 ml-9 group-hover:ml-0 group-hover:mb-32 group-hover:translate-x-0"></span>
              <span
                className="relative w-full text-left text-black dark:text-gray-300 transition-colors duration-300 ease-in-out group-hover:text-white dark:group-hover:text-black">VIEW ABSTRACT</span>
            </button>

            <button onClick={(e) => {
              onReadClick(paper)
            }}
                    className="btn-xs relative inline-flex items-center justify-start px-6 py-3 overflow-hidden font-medium transition-all bg-white dark:bg-dark-grey rounded hover:bg-white group">
                        <span
                          className="w-48 h-48 rounded rotate-[-40deg] btn-primary absolute bottom-0 left-0 -translate-x-full ease-out duration-500 transition-all translate-y-full mb-9 ml-9 group-hover:ml-0 group-hover:mb-32 group-hover:translate-x-0"></span>
              <span
                className="relative w-full text-left text-black dark:text-gray-300 transition-colors duration-300 ease-in-out group-hover:text-white dark:group-hover:text-black">READ PAPER</span>
            </button>

          </div>

        </div>
      </div>

    </>
  )
}
