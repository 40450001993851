import mixpanel from 'mixpanel-browser'

const TRACKING_ID = process.env.REACT_APP_MIXPANEL_ID


const InitializeAnalytics = () => {
  mixpanel.init(TRACKING_ID, {track_pageview: "full-url"});
  mixpanel.track_pageview();

}

const TrackAnalyticsEvent = (
  event,
  data = {}
) => {

  if (process.env.REACT_APP_API_BASE_URL.includes('localhost')) {
    console.log('Event tracking on localhost', event, data)
    // return
  }
  mixpanel.track(event, data)
}

export default InitializeAnalytics
export { TrackAnalyticsEvent }