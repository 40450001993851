export const ShareProvider = {
  CopyLink: 'copy',
  WhatsApp: 'whatsapp',
  Twitter: 'twitter',
  Facebook: 'facebook',
  Reddit: 'reddit',
  LinkedIn: 'linkedin',
  Telegram: 'telegram',
  Email: 'email'
}

const getWhatsappShareLink = (link) => `https://wa.me/?text=${encodeURIComponent(link)}`
const getTwitterShareLink = (link, text) => `https://twitter.com/share?url=${encodeURIComponent(link)}&text=${encodeURIComponent(`${text}`)}`
const getFacebookShareLink = (link) => `https://www.facebook.com/sharer/sharer.php?display=page&u=${encodeURIComponent(link)}`
const getRedditShareLink = (link, text) => `https://reddit.com/submit?url=${encodeURIComponent(link)}&title=${text}`
const getLinkedInShareLink = (link) => `https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(link)}`
const getTelegramShareLink = (link, text) => `https://t.me/share/url?url=${encodeURIComponent(link)}&text=${text}`
const getEmailShareLink = (link, subject) => `mailto:?subject=${subject}&body=${encodeURIComponent(link)}`
const copyShareLink = (text) => navigator.clipboard.writeText(text)

export const getShareLink = ({
                        provider,
                        link,
                        text
                      }) => {

  switch (provider) {
    case ShareProvider.WhatsApp:
      return getWhatsappShareLink(link)
    case ShareProvider.Twitter:
      return getTwitterShareLink(link, text ?? '')
    case ShareProvider.Facebook:
      return getFacebookShareLink(link)
    case ShareProvider.Reddit:
      return getRedditShareLink(link, text ?? '')
    case ShareProvider.LinkedIn:
      return getLinkedInShareLink(link)
    case ShareProvider.Telegram:
      return getTelegramShareLink(link, text ?? '')
    case ShareProvider.Email:
      return getEmailShareLink(link, text ?? '')
    case ShareProvider.CopyLink:
      return copyShareLink(text)
    default:
      return link
  }
}

const addTrackingQueryParams = ({
                                  link,
                                  userId,
                                  cid
                                }) => {
  // return link as is if not provided
  if (!link || !userId || !cid) {
    return link
  }

  const url = new URL(link)
  url.searchParams.set('userid', userId)
  url.searchParams.set('cid', cid)

  return url.toString()
}
