export const ShimmerCard = () => {
    return (
        <div className="card bg-base-400 shadow-xl bg-inherit lg:card-compact  sm-margin-5">
            <div className="animate-pulse">
                <div className="h-40 bg-gray-300 rounded-lg"></div>
                <div className="card-body">
                    <div className="card-title self-start">
                        <div className="flex">
                            <div className="flex">
                                <div className="rounded-full w-10 h-10 bg-gray-300 mx-auto shadow-md"></div>
                                <div className="w-16 h-5 bg-gray-300 mx-auto shadow-md mt-3 ml-2"></div>
                            </div>
                            <div className="flex-auto ml-3">
                                <div className="h-4 bg-gray-300 mb-1"></div>
                                <div className="h-3 bg-gray-300 w-1/4"></div>
                            </div>
                        </div>
                    </div>
                    <div className="h-5 bg-gray-300 mt-2"></div>
                    <div className="mt-3 flex justify-between">
                        <div className="h-8 w-16 bg-gray-300"></div>
                        <div className="h-8 w-16 bg-gray-300"></div>
                    </div>
                </div>
            </div>
        </div>
    );
};


export const ShimmerCardList = (props) => {
    const { repeats } = props
    return (
        <div className="grid lg:col-span-5 lg:grid-cols-5 gap-4 md:col-span-3 md:grid-cols-3 sm:col-span-2 sm:grid-cols-2">
            {
                [...Array(repeats)].map((item, index) => (
                    <ShimmerCard key={index} />
                ))
            }
        </div>
    )
}