import { useContext, useEffect, useState } from 'react'
import { AppContext } from '../state'
import { getArticles } from '../api'
import toast from 'react-hot-toast'
import { localStorageCacheProvider } from '../cache/localCacheProvider'

export function useArticlesLoader(useCache = false, cacheDurationInMinutes = 60) {
  const [posts, setPosts] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const [page, setPage] = useState(1)
  const [hasMore, setHasMore] = useState(true)
  const [isLoadingMore, setIsLoadingMore] = useState(false)
  const [loadingMsg, setLoadingMsg] = useState('Load More')
  const [feedType, setFeedType] = useState('latest')
  const { state } = useContext(AppContext)

  const loadArticles = async () => {
    if (!isLoadingMore) {
      setIsLoading(true)
    }
    let queryParams = `?page=${page}`
    if (feedType) {
      queryParams += `&feed=${feedType}`
    }
    if (state.filters.source) {
      queryParams += `&source=${state.filters.source.join(",")}`
    }
    if (state.filters.search.length >= 4) {
      // Only search, remove source filter
      queryParams += `&search=${state.filters.search}`
    }

    try {
      let data;
      if (useCache) {
        data = await localStorageCacheProvider.get(queryParams); // Try to get data from cache
      }
      if (!data) {
        data = await getArticles(queryParams);
        if (useCache) {
          await localStorageCacheProvider.set(queryParams, data, cacheDurationInMinutes);
        }
      }

      if (data.results.length === 0 && isLoadingMore) {
        setLoadingMsg("That's all for now!")
        setHasMore(false); // No more data available
        return;
      }
      if (isLoadingMore) {
        setPosts([...posts, ...data.results]);
      } else {
        setPosts(data.results);
      }
      if (data.num_pages === page) {
        // No more pages to load
        setHasMore(false)
        setLoadingMsg("That's all for now!")
      }
    } catch (error) {
      console.error('Error fetching articles:', error);
      toast.error(error.message || 'Error fetching articles!')
    } finally {
      // Re-enable loading state
      setIsLoadingMore(false)
      setIsLoading(false);
    }
  }

  useEffect(() => {
    loadArticles()
  }, [state.filters, page, feedType, useCache])

  const loadMoreArticles = () => {
    if (hasMore) {
      setIsLoadingMore(true)
      // Increment the page number
      setPage(page + 1);
    }
  }

  return {
    posts,
    isLoading,
    isLoadingMore,
    loadMoreArticles,
    loadingMsg,
    setPage,
    setFeedType
  }
}
