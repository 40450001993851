import {getFCMToken} from "./firebase";
import React from "react";
import toast from "react-hot-toast";

function onNotNowClick(t) {
    // Set a flag in local storage to indicate that notifications should be suppressed for a week
    const oneWeekFromNow = new Date();
    oneWeekFromNow.setDate(oneWeekFromNow.getDate() + 7);
    localStorage.setItem('suppressNotificationsUntil', oneWeekFromNow.getTime().toString())
    toast.dismiss(t.id)
}

export function askNotificationPermission() {
    toast((t) => (
        <div>
            <p className="text-sm">Enable notifications to stay updated on important news and updates.</p>
            <div className="flex flex-row justify-end">

                <button className="btn btn-xs btn-ghost mr-2" onClick={onNotNowClick}>Not Now
                </button>
                <button className="btn btn-xs btn-primary" onClick={() => {
                    Notification.requestPermission().then((permission) => {
                        if (permission === 'granted') {
                            toast.success('Notifications enabled!');
                            getFCMToken()
                        }
                    });
                }}>
                    Enable
                </button>

            </div>
        </div>
    ));
}

export function checkNotificationPermission() {
    const suppressUntil = localStorage.getItem('suppressNotificationsUntil')
    let askPermission = suppressUntil === null || Date.now() > parseInt(suppressUntil)

    // Check for notification permission
    if (Notification.permission !== 'granted' && askPermission) {
        askNotificationPermission();
    }
}