import React, {useEffect} from 'react';
import './App.css';

import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';

import {Toaster} from 'react-hot-toast';

import {checkNotificationPermission} from "./utils/notification";
import { AppProvider } from '@zoding/shared'
import NewsletterModal from '@zoding/shared/src/components/NewsletterModal'
import Navbar from '@zoding/shared/src/components/Navbar'
import InitializeAnalytics from '@zoding/shared/src/utils/analytics'
import Home from '@zoding/shared/src/pages/Home'
import About from '@zoding/shared/src/pages/About'
import Privacy from '@zoding/shared/src/pages/Privacy'
import Contact from '@zoding/shared/src/pages/Contact'
import Footer from './components/Footer'
import { getExtensionIcon } from './utils/extensionIcon'
import ResearchPaper from '@zoding/shared/src/pages/ResearchPaper'




function App() {

    useEffect(() => {
        InitializeAnalytics()
        checkNotificationPermission();
    }, [])

    return (
        <Router>
            <AppProvider>
                <div className="flex flex-col min-h-screen">
                    <div className="flex flex-col flex-grow">
                        <Toaster duration={10}/>
                        <NewsletterModal/>
                        <Navbar rightSideComponents={[getExtensionIcon()]}/>
                        <Routes>
                            <Route index element={<Home/>}/>
                            <Route path="/about" element={<About/>}/>
                            <Route path="/privacy" element={<Privacy/>}/>
                            <Route path="/contact" element={<Contact/>}/>
                            <Route path="/research-papers" element={<ResearchPaper/>}></Route>
                        </Routes>
                    </div>
                    <Footer/>
                </div>
            </AppProvider>
        </Router>
    );
}

export default App;
