
import { capitalize, intToAbbr } from '../utils'
import { formatDate } from '../utils'

function Card(props) {
    const {post, onShareClick, onReadClick} = props;


    return (
        <div className="card h-auto bg-base-100 dark:bg-base-200 shadow-xl lg:card-compact  sm-margin-5">
            <figure>
                <img className="h-40 w-full" src={post.picture_url} alt=""/>
            </figure>
            <div className="card-body">
                <div className="card-title self-start">
                    <div className="flex">
                        <div className="flex">
                            <div className="rounded-full w-10 h-10 overflow-hidden mx-auto shadow-md sm-margin-5">
                                <img
                                    className="object-cover w-full h-full"
                                    src={post.source.logo_url}
                                    alt="Logo"
                                />
                            </div>
                        </div>
                        <div className="flex-auto ml-3">
                            <h2 className="mb-1">{capitalize(post.source.name)}</h2>
                            <div className="text-xs font-normal text-left">
                                <span>{formatDate(post.published_at)}</span>&nbsp;&middot;&nbsp;
                                <span>{post.ttr}m read</span>&nbsp;&middot;&nbsp;
                                <span>{intToAbbr(post.views)} views</span>
                            </div>
                        </div>

                    </div>
                </div>

                <p className="font-medium text-ellipsis cursor-pointer transition duration-300 hover:underline" onClick={() => onReadClick(post)}>{post.title}</p>
                <div className="mt-3 flex justify-between">
                    {/* https://devdojo.com/tailwindcss/buttons */}
                    <button onClick={(e) => onShareClick(post)}
                            className="btn-xs relative inline-flex items-center justify-start px-6 py-3 overflow-hidden font-medium transition-all bg-white dark:bg-dark-grey rounded hover:bg-white group">
                        <span
                            className="w-48 h-48 rounded rotate-[-40deg] btn-primary absolute bottom-0 left-0 -translate-x-full ease-out duration-500 transition-all translate-y-full mb-9 ml-9 group-hover:ml-0 group-hover:mb-32 group-hover:translate-x-0"></span>
                        <span
                            className="relative w-full text-left text-black dark:text-gray-300 transition-colors duration-300 ease-in-out group-hover:text-white dark:group-hover:text-black">SHARE</span>
                    </button>
                    <button onClick={(e) => {
                        onReadClick(post)
                    }}
                            className="btn-xs relative inline-flex items-center justify-start px-6 py-3 overflow-hidden font-medium transition-all bg-white dark:bg-dark-grey rounded hover:bg-white group">
                        <span
                            className="w-48 h-48 rounded rotate-[-40deg] btn-primary absolute bottom-0 left-0 -translate-x-full ease-out duration-500 transition-all translate-y-full mb-9 ml-9 group-hover:ml-0 group-hover:mb-32 group-hover:translate-x-0"></span>
                        <span
                            className="relative w-full text-left text-black dark:text-gray-300 transition-colors duration-300 ease-in-out group-hover:text-white dark:group-hover:text-black">READ</span>
                    </button>

                </div>

            </div>
        </div>
    )
}

export default Card;


