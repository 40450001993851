import React, { useState } from 'react'
import { ShimmerCardList } from '../components'
import { TrackAnalyticsEvent } from '../utils'
import { useArticlesLoader } from '../hooks'
import ResearchPapersFilterBar from '../components/ResearchPapersFilterBar'
import { RPCard } from '../components/RPCard'
import { useResearchPapers } from '../hooks/useResearchPapers'


function ResearchPaper() {

  const { papers, isLoading, isLoadingMore, loadingMsg } = useResearchPapers()

  const [selectedPaper, setSelectedPaper] = useState(null)

  const onReadBtnClick = (paper) => {
    TrackAnalyticsEvent("Research Paper Read Btn Clicked", {"id": paper.id})
    window.open(`${process.env.REACT_APP_API_BASE_URL}/redirect/?url=${paper.url}&type=paper`, '_blank');
  }

  const onViewAbstractBtnClick = (paper) => {
    setSelectedPaper(paper)
    TrackAnalyticsEvent("Research Paper Abstract Btn Clicked", {"id": paper.id})
    window.abstract_view_modal.showModal()
  }

  return (
    <>
      <dialog id="abstract_view_modal" className="modal modal-bottom sm:modal-middle">
        <div className="modal-box">
          <form method="dialog">
            {/* if there is a button in form, it will close the modal */}
            <button className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2">✕</button>
          </form>
          <h3 className="font-bold text-lg">Paper Abstract</h3>
          <p className="py-4">{selectedPaper ? selectedPaper.abstract : ''}</p>
          <div className="modal-action">
            <form method="dialog">
              {/* if there is a button in form, it will close the modal */}
              <button className="btn btn-primary" onClick={(e) => {e.preventDefault(); onReadBtnClick(selectedPaper)}}>Read</button>
            </form>
          </div>
        </div>
      </dialog>
      <div className="container mx-auto">
        <ResearchPapersFilterBar onFilterClick={() => {
        }} />
        <div className="flex">
          <div className="flex-initial w-full mt-5 mb-5">
            <div className="grid lg:grid-cols-4 gap-4 md:grid-cols-4 sm:grid-cols-2 sm:grid-flow-col">
              <div
                className="grid lg:col-span-4 lg:grid-cols-4 gap-4 md:col-span-3 md:grid-cols-3 sm:col-span-2 sm:grid-cols-2">
                {isLoading ? (
                  <ShimmerCardList repeats={10} />
                ) : papers.length === 0 ? (
                  <div className="flex items-center justify-center col-span-5">
                    <h1 className="text-2xl tracking-tight font-extrabold text-center text-gray-900 dark:text-white"
                        style={{ textAlign: 'center', width: '100%' }}>Nothing is available...</h1>
                  </div>
                ) : (
                  papers.map((paper, index) => (
                    <RPCard key={index} paper={paper}
                            onReadClick={onReadBtnClick}
                            onViewAbstractClick={onViewAbstractBtnClick}
                    />
                  ))
                )}
              </div>
              <div className="col-span-1">
                {/* Google Ad code or component for the right side */}
              </div>
            </div>
            <div className="flex justify-center mt-5">
              {
                isLoadingMore ? (
                  <span className="loading loading-infinity loading-md text-primary"></span>
                ) : <button className="btn-link text-xs text-slate-500 italic no-underline"
                            onClick={() => {
                            }}>{papers.length > 0 ? loadingMsg : ''}</button>
              }
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default ResearchPaper
