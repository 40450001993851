import React from 'react'
import { ShimmerCardList } from '../components'
import CardWrapper from '../components/CardWrapper'
import Actionbar from '../components/Actionbar'
import SharePostModal from '../components/ShareModal'
import { TrackAnalyticsEvent } from '../utils/analytics'
import FilterModel from '../components/FilterModal'
import FilterBar from '../components/FilterBar'
import { useArticlesLoader } from '../hooks'


function Home() {
  const { posts, isLoading, isLoadingMore, loadMoreArticles, loadingMsg, setPage, setFeedType } = useArticlesLoader(true)


  const onTabChange = (tabName) => {
    setPage(1)
    setFeedType(tabName.toLowerCase())
    TrackAnalyticsEvent('tabChange', { 'tab_name': tabName })
  }

  const onFilterClick = () => {
    window.filter_modal.showModal()
    TrackAnalyticsEvent('filterModalOpened')
  }

  return (
    <>
      <Actionbar onTabChange={onTabChange} />
      <SharePostModal />
      <FilterModel />
      <div className="container mx-auto">
        <FilterBar onFilterClick={onFilterClick} />
        <div className="flex">
          <div className="flex-initial w-full mt-5 mb-5">
            <div className="grid lg:grid-cols-5 gap-4 md:grid-cols-3 sm:grid-cols-2 sm:grid-flow-col">
              <div
                className="grid lg:col-span-5 lg:grid-cols-5 gap-4 md:col-span-3 md:grid-cols-3 sm:col-span-2 sm:grid-cols-2">
                {isLoading ? (
                  <ShimmerCardList repeats={10} />
                ) : posts.length === 0 ? (
                  <div className="flex items-center justify-center col-span-5">
                    <h1 className="text-2xl tracking-tight font-extrabold text-center text-gray-900 dark:text-white"
                        style={{ textAlign: 'center', width: '100%' }}>Nothing is available...</h1>
                  </div>
                ) : (
                  posts.map((post) => (
                    <CardWrapper
                      key={post.id}
                      post={post}
                    />
                  ))
                )}
              </div>
              <div className="col-span-1">
                {/* Google Ad code or component for the right side */}
              </div>
            </div>
            <div className="flex justify-center mt-5">
              {
                isLoadingMore ? (
                  <span className="loading loading-infinity loading-md text-primary"></span>
                ) : <button className='btn-link text-xs text-slate-500 italic no-underline'
                            onClick={loadMoreArticles}>{posts.length > 0 ? loadingMsg : ''}</button>
              }
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Home
