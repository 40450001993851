export function getBrowserName() {
    const userAgent = navigator.userAgent;
    let browserName = "Unknown";

    if (userAgent.indexOf("Opera") !== -1 || userAgent.indexOf('OPR') !== -1) {
        browserName = 'Opera';
    } else if (userAgent.indexOf("Chrome") !== -1) {
        browserName = 'Chrome';
    } else if (userAgent.indexOf("Safari") !== -1) {
        browserName = 'Safari';
    } else if (userAgent.indexOf("Firefox") !== -1) {
        browserName = 'Firefox';
    } else if ((userAgent.indexOf("MSIE") !== -1) || (!!document.documentMode === true)) //IF IE > 10
    {
        browserName = 'IE';
    }

    return browserName;
}